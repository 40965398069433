import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../hooks/apis/accounts.api";

export interface accountState {
    data: Account[];
}

const initialState: accountState = {
    data: [],
};

export const accountsSlice = createSlice({
    name: "accounts",
    initialState,
    reducers: {
        setAccounts: (state, action: PayloadAction<Account[]>) => {
            state.data = action.payload;
        },
        addAccount: (state, action: PayloadAction<Account>) => {
            state.data.push(action.payload);
        },
        updateAccount: (state, action: PayloadAction<Account>) => {
            const index = state.data.findIndex((account) => account.id === action.payload.id);
            if (index !== -1) {
                state.data[index] = action.payload;
            }
        },
        deleteAccount: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter((account) => account.id !== action.payload);
        },
    },
});


export const { setAccounts,addAccount, updateAccount, deleteAccount } = accountsSlice.actions;

export default accountsSlice.reducer;