import React from "react";
import ModalWrapper from "../Generic/modal";
import AccountForm from "../Form/Account";
const AddAccountModal = ({
  setIsModalOpen,
  isModalOpen,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
}) => {
  return (
    <ModalWrapper
      title="Add Account"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <AccountForm editData={null} setIsModal={setIsModalOpen} />
    </ModalWrapper>
  );
};

export default AddAccountModal;
