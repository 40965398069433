import { api } from "@data-phone/react-resource-server-auth";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUser, deleteUser, setUsers, updateUser } from "../../redux/slices/users.slice";
import { useMessageToast } from "../../components/Layout/DefaultLayout";

export interface User {
    id: string;
    account_id: string;
    email: string;
    first_name: string;
    last_name: string;
    created_at: string;
    updated_at: string;
    password?: string;
    disabled_at: string | null;
    confirmed_at: string | null;
}

export const getUsers = async () => {
    const response = await api.get(`${process.env.REACT_APP_AUTH_API}/api/users`)
    return response
}

const addUserApi = async (data: User) => {
    const response = await api.post(`${process.env.REACT_APP_AUTH_API}/api/users`, data)
    return response
}


export const deleteUserApi = async (id: string) => {
    await api.delete(`${process.env.REACT_APP_AUTH_API}/api/users/${id}`)
    return id
}


const updateUserApi = async (data: {
    id: string;
    updateUserData: User
}) => {
    const response = await api.patch(`${process.env.REACT_APP_AUTH_API}/api/users/${data.id}`, data.updateUserData)
    return response
}

export const useGetUsers = () => {
    const dispatch = useDispatch()
    return useQuery("users", getUsers, {
        enabled: false,
        onSuccess: (response) => {
            dispatch(setUsers(response.data))
        }
    })
}

export const useAddUser = () => {
    const dispatch = useDispatch()
    const {messageApi} = useMessageToast()

    return useMutation(addUserApi, {
        onSuccess: (response) => {
            dispatch(addUser(response.data[0]))
        },
        onError: (error: any) => {
            if(error.response.data.errors[0].msg){
                messageApi.error(error.response.data.errors[0].msg)
            }
            else{
                messageApi.error("User already exists")
            }
        }
    })
}

export const useUpdtateUser = () => {
    const {messageApi} = useMessageToast()
    const dispatch = useDispatch()  
    return useMutation(updateUserApi, {
        onSuccess: (response) => {
            dispatch(updateUser(response.data[0]))
        },
        onError: (error: any) => {
            if(error.response.data.errors[0].msg){
                messageApi.error(error.response.data.errors[0].msg)
            }
            else{
                messageApi.error("Something went wrong")
            }
        }
    })
}

export const useDeleteUser = () => {
    const {messageApi} = useMessageToast()
    const dispatch = useDispatch()  
    const navigate = useNavigate()
    return useMutation(deleteUserApi, {
        onSuccess: (response) => {
            dispatch(deleteUser(response))
            messageApi.success("User deleted successfully")
            navigate("/")
        },
        onError: (error: any) => {
            if(error.response.data.errors[0].msg){
                messageApi.error(error.response.data.errors[0].msg)
            }
            else{
                messageApi.error("Something went wrong")
            }
        }
    })
}