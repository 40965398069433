import AccountInformation from "../pages/AccountInfo";
import Accounts from "../pages/Accounts";
import Permissions from "../pages/Permissions";
import UserInformation from "../pages/UserInfo";
import Users from "../pages/Users";


const coreRoutes = [
  {
    path: "/",
    title: "Users",
    component: Users,
  },
  {
    path: "/user/:id",
    title: "User",
    component:UserInformation
  },
  {
    path: "/accounts",
    title: "Accounts",
    component: Accounts,
  },
  {
    path: "/account/:id",
    title: "Account",
    component: AccountInformation,
  },
  {
    path: "/permissions",
    title: "Permissions",
    component: Permissions,
  }
];




const routes = [...coreRoutes];
export { routes};
