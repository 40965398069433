import { Button, Descriptions, Flex, Modal, Spin, Typography } from 'antd';
import type { DescriptionsProps } from 'antd';
import { useSelector } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { RootState } from '../redux';
import { useParams } from 'react-router-dom';
import {  Account, useDeleteAccount, useGetAccounts } from '../hooks/apis/accounts.api';
import Loader from '../components/Layout/loader';
import { LoadingOutlined } from "@ant-design/icons";
import EditAccountModal from '../components/Modal/EditAccountModal';
const { confirm } = Modal;

const AccountInformation = () => {

    const { data } = useSelector((state: RootState) => state.accounts)
    const { refetch: refetchAccounts } = useGetAccounts()
    const [editAccountModal, setEditAccountModal] = useState(false)
    const { mutate: deleteAccountMutate, isLoading: isLoadingDeleteAccount } = useDeleteAccount()
    const { id } = useParams<{ id: string }>();
    const [account, setAccount] = useState<Account | null>(data.find((account) => account.id === id) || null);

    useEffect(() => {
        if (data.length === 0) {
            refetchAccounts()
        }
    }, [])


    useEffect(() => {
    if (data.length > 0) {
            setAccount(data.find((account) => account.id === id) || null)
        }
    }, [data, id])


    if (isLoadingDeleteAccount || !account) {
        return (
            <Flex style={{ height: "100vh" }}>
                {" "}
                <Loader />
            </Flex>
        );
    }

    const showDeleteConfirm = (record: Account) => {
        confirm({
            title: 'Are you sure you want to delete this account?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteAccountMutate(record.id)
            },
        });
    };

    let items: DescriptionsProps['items'] = [
       
        {
            key: '1',
            label: 'Name',
            span: 2,
            children: account.name,
        },
        {
            key: '2',
            label: 'Account Code',
            span: 2,
            children: account.accountcode,
        },
        {
            key: '5',
            label: 'Domain',
            span: 2,
            children: account.domain,
        },
        {
            key: '6',
            label: 'Enabled',
            span: 2,
            children: account.enabled ? "Yes" : "No",
        },
        {
            key: '7',
            label: 'Description',
            span: 4,
            children: account.description,
        }
    ];

    return <div
        style={{ padding: "10px" }}
    >
        {editAccountModal && <EditAccountModal setIsModalOpen={setEditAccountModal} isModalOpen={editAccountModal} editData={account} />}
        <Flex justify="space-between">
            <Typography.Title>Account information</Typography.Title>
            <Flex gap={10}>
                {
                    <Button type="primary"
                        style={{ background: "rgb(101, 59, 250)" }} onClick={() => {
                            setEditAccountModal(true)
                        }}>Edit</Button>
                }
                {
                    isLoadingDeleteAccount ?
                        <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                        /> :
                        <Button type="primary" danger onClick={() => {
                            showDeleteConfirm(account)
                        }}>Delete</Button>
                }

            </Flex>
        </Flex>
        <Descriptions layout="vertical" bordered items={items} />
    </div>

}


export default AccountInformation;