import React from "react";
import ModalWrapper from "../Generic/modal";
import { Account } from "../../hooks/apis/accounts.api";
import AccountForm from "../Form/Account";
const EditAccountModal = ({
  setIsModalOpen,
  isModalOpen,
  editData,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  editData: Account | null;
}) => {
  return (
    <ModalWrapper
      title="Edit Account"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <AccountForm editData={editData} setIsModal={setIsModalOpen} />
    </ModalWrapper>
  );
};

export default EditAccountModal;
