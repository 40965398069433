import { Modal, Space, Spin, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import { Account, useDeleteAccount } from '../../hooks/apis/accounts.api';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from 'react';
import EditAccountModal from '../Modal/EditAccountModal';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
const { confirm } = Modal;

type ColumnsType<T extends object> = TableProps<T>['columns'];

const AccountsTable = ({
    accounts,
    loading,
    searchInput
}: {
    accounts: Account[]
    loading: boolean,
    searchInput: string
}) => {
    const [editData, setEditData] = useState<Account | null>(null)
    const [editAccountModal, setEditAccountModal] = useState(false)
    const [deleteAccountId, setDeleteAccountId] = useState<string | null>(null)
    const { mutate: deleteAccountMutate, isLoading: isLoadingDeleteAccount } = useDeleteAccount()
    const navigate = useNavigate()
    const showDeleteConfirm = (record: Account) => {
        confirm({
            title: 'Are you sure you want to delete this account?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                setDeleteAccountId(`${record.id}`)
                deleteAccountMutate(record.id)
            },
            onClose() {
                setDeleteAccountId(null)  
            },
            onCancel() {
                setDeleteAccountId(null)
            },
        });
    };
    const columns: ColumnsType<Account> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, { name ,id}) => <a onClick={()=>{navigate(`/account/${id}`)}}>{name}</a>,
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
            render: (_, { domain }) => <a>{domain}</a>,
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (_, { accountcode }) => <Tag>{accountcode}</Tag>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {
                        <CiEdit style={{ cursor: "pointer" }} onClick={() => {
                            setEditData(record)
                            setEditAccountModal(true)
                        }}
                        />
                    }
                    {
                        isLoadingDeleteAccount && deleteAccountId === `${record.id}` ?
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                            />
                            :
                            <MdDeleteOutline onClick={() => {
                                showDeleteConfirm(record)
                             }} style={{ cursor: "pointer" }}/>
                    }
                </Space>
            ),
        },
    ];

    return (
        <div>
            {
                editAccountModal && <EditAccountModal setIsModalOpen={setEditAccountModal} isModalOpen={editAccountModal} editData={editData} />
            }
            <Table loading={loading} scroll={{ y: 450 }} style={{ width: "100%", height: "500px" }} columns={columns} pagination={{ position: ["bottomCenter"] }} dataSource={accounts.filter(account => {
                if (searchInput === "") return account
                return account.name?.toLowerCase().includes(searchInput.toLowerCase()) || account.domain?.toLowerCase().includes(searchInput.toLowerCase()) || account.accountcode?.toLowerCase().includes(searchInput.toLowerCase()) || account.id?.includes(searchInput)
            })} />
        </div>
    );
};

export default AccountsTable;