import React from "react";
import { Header } from "antd/es/layout/layout";
import dotMenu from "../assets/images/icon/dotsmenu.svg";
import LiveCalls from "../assets/images/icon/Live-calls.svg";
import CallCenter from "../assets/images/icon/Call-Center.svg";
import SMS from "../assets/images/icon/SMS.svg";
import Contacts from "../assets/images/icon/Contacts.svg";
import PBX from "../assets/images/icon/PBX-Portal.svg";
import logo from "../assets/images/icon/dataphone-header.svg";
import report from "../assets/images/icon/Reports.svg";
import { Avatar, Flex, Popover, Typography } from "../../node_modules/antd";
import { Dropdown } from "antd";
import { useState } from "react";
const { Text } = Typography;
export const LayoutHeader = ({ name, logout, data, hasPermission, navigate }) => {
    const items = [
        {
            label: "Logout",
            key: "0",
            onClick: () => {
                logout();
            },
        },
    ];
    const [navigationItems, setNavigationItems] = useState(items);
    const avatarStyle = {
        verticalAlign: "middle",
        color: "black",
        fontWeight: "bold",
        cursor: "pointer",
        textTransform: "capitalize"
    };
    const dotMenuStyle = {
        border: "1px solid white",
        borderRadius: "25px",
        cursor: "pointer",
        width: "30px",
        height: "30px",
        margin: "auto",
        padding: "5px",
    };
    const TextStyle = {
        color: "rgb(119, 117, 135)",
    };
    const HeaderStyle = {
        background: "#130d38",
        padding: "15px",
    };
    const HeaderContentStyle = { height: "100%" };
    const HeaderMenu = { gap: "20px" };
    const HeaderNameStyle = {
        color: "white",
        margin: "auto",
        textTransform: "capitalize",
    };
    const headerLogo = {
        cursor: "pointer"
    };
    const dotMenuContent = (React.createElement(Flex, { vertical: true },
        React.createElement(Flex, null,
            React.createElement(Flex, { vertical: true, align: "center", className: "dot-menu-icons" },
                React.createElement("img", { src: LiveCalls, alt: "LiveCalls" }),
                React.createElement(Text, { style: TextStyle }, "Live Calls")),
            React.createElement(Flex, { vertical: true, align: "center", className: "dot-menu-icons" },
                React.createElement("img", { src: CallCenter, alt: "CallCenter" }),
                React.createElement(Text, { style: TextStyle }, "Call center")),
            React.createElement(Flex, { vertical: true, align: "center", className: "dot-menu-icons" },
                React.createElement("img", { src: SMS, alt: "SMS" }),
                React.createElement(Text, { style: TextStyle }, "SMS"))),
        React.createElement(Flex, null,
            React.createElement(Flex, { vertical: true, align: "center", className: "dot-menu-icons" },
                React.createElement("img", { src: Contacts, alt: "Contacts" }),
                React.createElement(Text, { style: TextStyle }, "Contacts")),
            " ",
            React.createElement(Flex, { vertical: true, align: "center", className: "dot-menu-icons" },
                React.createElement("img", { src: PBX, alt: "PBX" }),
                React.createElement(Text, { style: TextStyle }, "PBX Portal")),
            " ",
            React.createElement(Flex, { vertical: true, align: "center", className: "dot-menu-icons" },
                React.createElement("img", { src: report, alt: "Reports" }),
                React.createElement(Text, { style: TextStyle }, "Reports")))));
    return (React.createElement(Header, { style: HeaderStyle },
        React.createElement(Flex, { align: "center", justify: "space-between", style: HeaderContentStyle },
            React.createElement("img", { style: headerLogo, src: logo, alt: "header logo", onClick: () => navigate("/") }),
            React.createElement(Flex, { style: HeaderMenu },
                React.createElement(Popover, { placement: "bottom", content: dotMenuContent },
                    React.createElement("img", { style: dotMenuStyle, src: dotMenu, alt: "dot menu" })),
                React.createElement(Text, { style: HeaderNameStyle }, name),
                React.createElement(Dropdown, { menu: { items: navigationItems }, trigger: ["click"] },
                    React.createElement("a", { onClick: (e) => e.preventDefault() },
                        React.createElement(Avatar, { style: { ...avatarStyle, background: "rgb(229, 255, 204)" }, size: "large" }, name[0])))))));
};
