import { useSelector } from "react-redux";
import UsersTable from "../components/Tables/Users";
import { RootState } from "../redux";
import { useGetUsers } from "../hooks/apis/users.api";
import { useEffect, useState } from "react";
import { useGetAccounts } from "../hooks/apis/accounts.api";
import { IoSearch } from "react-icons/io5";
import { Button, Flex, Input, Typography } from "antd";
import AddUserModal from "../components/Modal/AddUserModal";


const inputSearchStyle: React.CSSProperties = {
    borderRadius: "25px",
    border: "none",
    padding: "10px",
    width: "200px",
    marginBottom:"10px"
};

const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "#6b7280",
    cursor: "pointer",
  };



const Users = () => {
    const {  isLoading } = useGetUsers()
    const [searchInput, setSearchInput] = useState("");
    const { refetch: refetchAccounts } = useGetAccounts()
    const [addUserModal, setAddUserModal] = useState(false)

    const { data } = useSelector((state: RootState) => state.users)
    return <div style={{ padding:"20px" }}>
        {
            addUserModal && <AddUserModal  setIsModalOpen={setAddUserModal} isModalOpen={addUserModal} />
        }
        <Flex justify="space-between" align='center' style={{ padding: "10px" }}>
            <Typography.Title>{`Users`}</Typography.Title>{" "}
            {
                <Button type="primary" onClick={() => {
                    setAddUserModal(true)
                }}>Add User</Button>
            }

        </Flex>
        <Input
            style={{
                ...inputSearchStyle,
            }}
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            size="middle"
            placeholder="Search"
            prefix={<IoSearch style={searchIconStyle} />}
        />
        <UsersTable searchInput={searchInput} loading={isLoading} users={data} />
    </div>;
};
export default Users