import { api } from "@data-phone/react-resource-server-auth";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMessageToast } from "../../components/Layout/DefaultLayout";
import { addAccount, deleteAccount, setAccounts, updateAccount } from "../../redux/slices/accounts.slice";

export interface Account {
  id: string;
  parent_id: string | null;
  name: string;
  accountcode: string;
  enabled: boolean;
  created_at: string;
  updated_at: string;
  disabled_at: string | null;
  description: string;
  domain: string;
}

const getAccounts = async () => {
  const response = await api.get(`${process.env.REACT_APP_AUTH_API}/api/accounts`)
  return response
}

const addAccountApi = async (data: Account) => {
  const response = await api.post(`${process.env.REACT_APP_AUTH_API}/api/accounts`, data)
  return response
}

const deleteAccountApi = async (id: string) => {
  await api.delete(`${process.env.REACT_APP_AUTH_API}/api/accounts/${id}`)
  return id
}

const updateAccountApi = async (data:{
  id: string;
  updateAccount: Account
}) => {
  const response = await api.patch(`${process.env.REACT_APP_AUTH_API}/api/accounts/${data.id}`, data.updateAccount)
  return response
}

export const useAddAccount = () => {
  const dispatch = useDispatch()
  const {messageApi} = useMessageToast()

  return useMutation(addAccountApi, {
    onSuccess: (response) => {
      messageApi.success("Account added successfully")
      dispatch(addAccount(response.data))
    },
    onError: (error: any) => {
        if(error.response.data.errors[0].msg){
            messageApi.error(error.response.data.errors[0].msg)
        }
        else{
            messageApi.error("Something went wrong")
        }
    }
  })
}

export const useUpdateAccount = () => {
  const dispatch = useDispatch()
  const {messageApi} = useMessageToast()

  return useMutation(updateAccountApi, {
    onSuccess: (response) => {
      messageApi.success("Account updated successfully")
      dispatch(updateAccount(response.data[0]))
    },
    onError: (error: any) => {
        if(error.response.data.errors[0].msg){
            messageApi.error(error.response.data.errors[0].msg)
        }
        else{
            messageApi.error("Something went wrong")
        }
    }
  })
}

export const useDeleteAccount = () => {
  const {messageApi} = useMessageToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return useMutation(deleteAccountApi, {
    onSuccess: (response) => {
      messageApi.success("Account deleted successfully")
      dispatch(deleteAccount(response))
      navigate("/accounts")
    },
    onError: (error: any) => {
        if(error.response.data.errors[0].msg){
            messageApi.error(error.response.data.errors[0].msg)
        }
        else{
            messageApi.error("Something went wrong")
        }
    }
  })
}

export const useGetAccounts = () => {
  const dispatch = useDispatch()
  return useQuery("accounts", getAccounts, {
    enabled: false,
    onSuccess: (response) => {
      dispatch(setAccounts(response.data))
    }
  })
}