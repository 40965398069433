import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { useEffect, useState } from "react";
import { useGetAccounts } from "../hooks/apis/accounts.api";
import { IoSearch } from "react-icons/io5";
import { Button, Flex, Input, Typography } from "antd";
import AccountsTable from "../components/Tables/Accounts";
import AddAccountModal from "../components/Modal/AddAccountModal";



const inputSearchStyle: React.CSSProperties = {
    borderRadius: "25px",
    border: "none",
    padding: "10px",
    width: "200px",
    marginBottom: "10px"
};

const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "#6b7280",
    cursor: "pointer",
};



const Accounts = () => {
    const [searchInput, setSearchInput] = useState("");
    const { refetch: refetchAccounts, isLoading } = useGetAccounts()
    const { data } = useSelector((state: RootState) => state.accounts)
    const [addAccountModal, setAddAccountModal] = useState(false)
    useEffect(() => {
        refetchAccounts()
    }, [])
    return <div style={{ padding: "20px" }}>
        {
            addAccountModal && <AddAccountModal setIsModalOpen={setAddAccountModal} isModalOpen={addAccountModal} />
        }
        <Flex justify="space-between" align='center' style={{ padding: "10px" }}>
            <Typography.Title>{`Accounts`}</Typography.Title>{" "}
            {
                <Button type="primary" onClick={() => {
                    setAddAccountModal(true)
                }}>Add Account</Button>
            }
        </Flex>
        <Input
            style={{
                ...inputSearchStyle,
            }}
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            size="middle"
            placeholder="Search"
            prefix={<IoSearch style={searchIconStyle} />}
        />
        <AccountsTable searchInput={searchInput} loading={isLoading} accounts={data} />
    </div>;
};
export default Accounts