import { Col, Layout, Row } from "antd";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { RootState } from "../../redux";
import { LayoutHeader } from "@data-phone/react-generic";
import { usePermissions } from "../../hooks/permissions/usePermissions";
import ChatSideBar from "./Sidebar";



const DefaultLayout = ({
  logout
}: {
  logout: () => void
}) => {
  const layoutStyle: React.CSSProperties = {
    height: "100vh",
    background: "#ffffff !important",
  };
  const user = useSelector((state: RootState) => state.user.data);
  const [messageApi, contextHolder] = message.useMessage();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate()


  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Rethink Sans",
        },
      }}
    >
      <Layout style={layoutStyle}>
        {contextHolder}
        <LayoutHeader logout={logout} name={`${user?.firstName} ${user?.lastName}`} data={user} hasPermission={hasPermission} navigate={navigate} />
        <Layout>
          <Row style={{ height: "100%" }}>
            <Col span={4}>
              <ChatSideBar/>
            </Col>
            <Col span={20}>
              <Outlet context={{ messageApi } satisfies MessageInstance | any} />
            </Col>
          </Row>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default DefaultLayout;

export function useMessageToast() {
  return useOutletContext<any>();
}
