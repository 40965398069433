import React, { useState } from 'react';
import { Modal, Radio, Space, Spin, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import { useDeleteUser, User } from '../../hooks/apis/users.api';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { CiEdit } from 'react-icons/ci';
import { MdDeleteOutline } from 'react-icons/md';
import EditUserModal from '../Modal/EditUserModal';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';


const { confirm } = Modal;


type ColumnsType<T extends object> = TableProps<T>['columns'];







const UsersTable = ({
    users,
    loading,
    searchInput
}: {
    users: User[]
    loading: boolean,
    searchInput: string
}) => {
    let { data } = useSelector((state: RootState) => state.accounts)
    const [editData, setEditData] = useState<User | null>(null)
    const [editUserModal, setEditUserModal] = useState(false)
    const [deleteUserId, setDeleteUserId] = useState<string | null>(null)
    const navigate = useNavigate()
    const { mutate: deleteUserMutate, isLoading: isLoadingDeleteUser } = useDeleteUser()
    const showDeleteConfirm = (record: User) => {
        confirm({
            title: 'Are you sure you want to delete this user?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                setDeleteUserId(`${record.id}`)
                deleteUserMutate(record.id)
            },
            onClose() {
                setDeleteUserId(null)
            },
            onCancel() {
                setDeleteUserId(null)
            },
        });
    };
    const columns: ColumnsType<User> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, user) => <a onClick={
                () => navigate(`/user/${user.id}`)
            }>{user.first_name} {user.last_name}</a>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Account',
            dataIndex: 'account',
            key: 'account',
            render: (_, { account_id }) => <a onClick={() => navigate(`/account/${account_id}`)}>{data.find(account => account.id === account_id)?.name}</a>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <CiEdit style={{ cursor: "pointer" }}
                        onClick={() => {
                            setEditData(record)
                            setEditUserModal(true)
                        }}
                    />
                    {
                        isLoadingDeleteUser && deleteUserId === `${record.id}` ?
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                            />
                            :
                            <MdDeleteOutline onClick={() => {
                                showDeleteConfirm(record)
                            }} style={{ cursor: "pointer" }} />
                    }                </Space>
            ),
        },
    ];

    return (
        <div>
            {
                editUserModal && <EditUserModal setIsModalOpen={setEditUserModal} isModalOpen={editUserModal} editData={editData} />
            }
            <Table loading={loading} scroll={{ y: 450 }} style={{ width: "100%", height: "500px" }} columns={columns} pagination={users.length > 10 && { position: ["bottomCenter"] }} dataSource={users.filter((user) => {
                if (searchInput === "") return user
                return user.first_name.toLowerCase().includes(searchInput.toLowerCase()) || user.last_name.toLowerCase().includes(searchInput.toLowerCase()) || user.email.toLowerCase().includes(searchInput.toLowerCase()) || user.id.toLowerCase().includes(searchInput.toLowerCase())
            })} />
        </div>
    );
};

export default UsersTable;