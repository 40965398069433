import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "../../hooks/apis/rbac.api";

export interface rolesInterface {
  data: Role[] ;
}

const initialState: rolesInterface = {
  data: [],
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.data = action.payload;
    },
  },
});


export const { setRoles } = rolesSlice.actions;

export default rolesSlice.reducer;