import React from "react";
import ModalWrapper from "../Generic/modal";
import UserForm from "../Form/User";
const AddUserModal = ({
  setIsModalOpen,
  isModalOpen,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
}) => {
  return (
    <ModalWrapper
      title="Add User"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <UserForm editData={null} setIsModal={setIsModalOpen} />
    </ModalWrapper>
  );
};

export default AddUserModal;
