import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import { useAuthModule } from "@data-phone/react-resource-server-auth"
import { Flex, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import Loader from './components/Layout/loader';
import { setUser } from './redux/slices/user.slice';
import { AppDispatch } from './redux';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DefaultLayout from './components/Layout/DefaultLayout';
import { routes } from './routes';
import { useGetRoles } from './hooks/apis/rbac.api';
import { useGetUsers } from './hooks/apis/users.api';
import { useGetAccounts } from './hooks/apis/accounts.api';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


function App() {
  const { logout, data } = useAuthModule()
  const [isLoading, setIsLoading] = useState(true);
  const dispatch: AppDispatch = useDispatch()
  const { refetch: refetchRoles } = useGetRoles()
  const {  refetch: refetchUsers } = useGetUsers()
  const { refetch: refetchAccounts } = useGetAccounts()


  useEffect(() => {
    if (data.sessionData) {
      refetchRoles()
      refetchUsers()
      refetchAccounts()
      dispatch(setUser(data.sessionData))
      setIsLoading(false);
    }
  }, [data])

  if (isLoading) {
    return (
      <Flex style={{ height: "100vh" }}>
        {" "}
        <Loader />
      </Flex>
    );
  }

  return (
    <Routes >
      <Route element={<DefaultLayout logout={logout} />}>
        {routes.map((routes, index) => {
          const { path, component: Component } = routes;
          return (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <Component />
                </Suspense>
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
}

export default App;
