import { useSelector } from "react-redux"
import { RootState } from "../../redux"
import { Button, Form, FormProps, Select } from "antd"
import { useAssignRole } from "../../hooks/apis/rbac.api"
import { useEffect } from "react"

const AssignUserRole = (
    {
        setIsModal,
        role_id,
        user_id,
        setRoleIds,
        role_ids
    }:{
        setIsModal: React.Dispatch<React.SetStateAction<boolean>>
        role_id: string,
        user_id: string,
        setRoleIds:React.Dispatch<React.SetStateAction<string[]>>
        role_ids:string[]
    }
) => {
    const { data: roles } = useSelector((state: RootState) => state.roles)
    const [form] = Form.useForm();
    const { mutate: assignRoleMutate, isLoading: isLoadingAssign ,isSuccess: isSuccessAssign,data} = useAssignRole()

    useEffect(()=>{
        if(data && data.data){
            setRoleIds(data.data)
        }
    },[data])

    useEffect(() => {
        if(isSuccessAssign){
            setIsModal(false)
        }
    },[isSuccessAssign])
    const onFinish: FormProps<{
        role_id: string
    }>["onFinish"] = (values) => {
        assignRoleMutate({ role_id: values.role_id, user_id: user_id })
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    return <Form
        style={{ padding: "10px" }}
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        onFinish={onFinish}
    >

        <Form.Item
            label="Role"
            name="role_id"
            
            rules={[{ required: true, message: "Please Select Role" }]}
        >
            <Select
                showSearch
                defaultValue={role_id}
                placeholder="Select Role"
                optionFilterProp="children"
                filterOption={filterOption}
                options={roles.filter(role => !role_ids?.includes(role.name)).map(role => {
                    return {
                        label: role.description,
                        value: role.name
                    }
                })}
            />
        </Form.Item>

        <Form.Item>
            <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={isLoadingAssign}
                loading={isLoadingAssign}
                htmlType="submit"
            >
                {"Assign Role"}
            </Button>
        </Form.Item>
    </Form>

}

export default AssignUserRole