import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  User } from "../../hooks/apis/users.api";

export interface userState {
    data: User[];
}

const initialState: userState = {
    data: [],
};

export const usersSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<User[]>) => {
            state.data = action.payload;
        },
        updateUser: (state, action: PayloadAction<User>) => {
            const index = state.data.findIndex((user) => user.id === action.payload.id);
            if (index !== -1) {
                state.data[index] = action.payload;
            }
        },
        addUser: (state, action: PayloadAction<User>) => {
            state.data.push(action.payload);
        },
        deleteUser: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter((user) => user.id !== action.payload);
        },
    },
});


export const { setUsers, updateUser, addUser, deleteUser } = usersSlice.actions;

export default usersSlice.reducer;