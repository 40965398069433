import { Avatar, Flex, List } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { HeadingText } from "../Generic/Typography";
import { useMatch, useNavigate } from "react-router-dom";

const contentStyle: React.CSSProperties = {
    height: "100%",
    borderRight: "1px solid rgb(223, 226, 232)",
    background: "#ffff",
    padding: "20px",
};

const cardStyle = {
    border: "none",
    padding: "10px",
    borderRadius: "6px",
    cursor: "pointer",
};

const ChatSideBarItem: React.CSSProperties = {
    width: "100%",
    borderRadius: 6,
  };




const ChatSideBar = () => {
    const navigate = useNavigate()
    const [active, setActive] = useState("")
    const menu = [
        {
            label: "Users",
            path: "/",
        },
        {
            label: "Accounts",
            path: "/accounts",
        
        },
        {
            label: "Permissions",
            path: "/permissions",
         
        },
    ]

    const match = useMatch(`/${active}`)
 
    return (
        <Content style={contentStyle}>
            <List
                itemLayout="horizontal"
                dataSource={menu}
                renderItem={(menu, index: number) => (
                    <List.Item
                        onClick={() => {
                            navigate(`${menu.path}`)
                            setActive(menu.path)
                        }}
                        style={{
                            ...cardStyle,
                            background: match?.pathname === `${menu.path}`  ? "#eff4fb" : "#fff",
                        }}
                    >
                        <Flex style={ChatSideBarItem} justify="space-between" >
                            <Flex gap={10}>
                                <HeadingText
                                    text={menu.label}
                                ></HeadingText>
                            </Flex>
                        </Flex>
                    </List.Item>
                )}
            />
        </Content>
    );
};

export default ChatSideBar;
