import React from "react";
import ModalWrapper from "../Generic/modal";
import UserForm from "../Form/User";
import AssignUserRole from "../Form/AssignRole";
const AssignRoleModal = ({
  setIsModalOpen,
  isModalOpen,
  role_id,
  user_id,
  setRoleIds,
  role_ids
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  role_id: string
  user_id: string
  setRoleIds:React.Dispatch<React.SetStateAction<string[]>>
  role_ids:string[]
}) => {
  return (
    <ModalWrapper
      title="Assign User Role"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <AssignUserRole role_ids={role_ids} setRoleIds={setRoleIds}  user_id={user_id} setIsModal={setIsModalOpen} role_id={role_id} />
    </ModalWrapper>
  );
};

export default AssignRoleModal;
