import React from "react";
import ModalWrapper from "../Generic/modal";
import UserForm from "../Form/User";
import { User } from "../../hooks/apis/users.api";
const EditUserModal = ({
  setIsModalOpen,
  isModalOpen,
  editData,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  editData: User | null;
}) => {
  return (
    <ModalWrapper
      title="Edit User"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <UserForm editData={editData} setIsModal={setIsModalOpen} />
    </ModalWrapper>
  );
};

export default EditUserModal;
