import { Button, Descriptions, Flex, List, Modal, Spin, Typography } from 'antd';
import type { DescriptionsProps } from 'antd';
import { useSelector } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { RootState } from '../redux';
import { useDeleteUser, useGetUsers, User } from '../hooks/apis/users.api';
import { useParams } from 'react-router-dom';
import { useDeleteAccount, useGetAccounts } from '../hooks/apis/accounts.api';
import Loader from '../components/Layout/loader';
import EditUserModal from '../components/Modal/EditUserModal';
import { LoadingOutlined } from "@ant-design/icons";
import { useAssignRole, useDeleteUserRole, useGetRoleById } from '../hooks/apis/rbac.api';
import { CiEdit } from 'react-icons/ci';
import AssignRoleModal from '../components/Modal/AssignUserRoleModal';
const { confirm } = Modal;

const UserInformation = () => {
    const { id } = useParams<{ id: string }>();
    const { refetch: refetchRole, data: role, isFetching: isLoadingRole } = useGetRoleById(id as string)
    const [role_ids, setRoleIds] = useState<string[]>(role || []);
    const { data } = useSelector((state: RootState) => state.accounts)
    const { data: users } = useSelector((state: RootState) => state.users)
    const { data: roles } = useSelector((state: RootState) => state.roles)
    const { refetch: refetchUsers, isLoading } = useGetUsers()
    const { refetch: refetchAccounts } = useGetAccounts()
    const [editUserModal, setEditUserModal] = useState(false)
    const [assingRoleModal, setAssingRoleModal] = useState(false)
    const { mutate: deleteUserMutate, isLoading: isLoadingDeleteUser } = useDeleteUser()
    const { mutate: deleteUserRoleMutate, isLoading: isLoadingDeleteUserRole } = useDeleteUserRole(setRoleIds)
    const [user, setUser] = useState<User | null>(users.find((user) => user.id === id) || null);




    useEffect(() => {
        refetchRole()
        if (users.length === 0 || data.length === 0) {
            refetchUsers()
            refetchAccounts()
        }
    }, [])

    useEffect(() => {
        setRoleIds(role)
    }, [role])


    useEffect(() => {
        if (users.length > 0) {
            setUser(users.find((user) => user.id === id) || null)
        }
    }, [users, id])

 


    if (isLoading || !user || isLoadingRole) {
        return (
            <Flex style={{ height: "100vh" }}>
                {" "}
                <Loader />
            </Flex>
        );
    }

    const showDeleteConfirm = (record: User) => {
        confirm({
            title: 'Are you sure you want to delete this user?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteUserMutate(record.id)
            },
        });
    };

    const showDeleteConfirmUserRole = (roleId: string) => {
        confirm({
            title: 'Are you sure you want to remove this role?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteUserRoleMutate({ user_id: id as string, role_id: roleId })
                setTimeout(() => {
                    setRoleIds(role_ids.filter((role) => role !== roleId))
                }, 2000)
            },
        });
    };

    let items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Name',
            span: 2,
            children: `${user?.first_name} ${user?.last_name}`,
        },
        {
            key: '2',
            label: 'Email',
            span: 2,
            children: user?.email,
        },
        {
            key: '5',
            label: 'Account',
            span: 4,
            children: data.find((account) => account.id === user?.account_id)?.name
        },
        {
            key: '6',
            label: <Flex justify='space-between' gap={10}>
                Roles
                <Button type="primary" onClick={() => {
                    setAssingRoleModal(true)
                }}
                >Add Role</Button>
            </Flex>,
            span: 4,

            children: <List
                bordered
                itemLayout='horizontal'
                dataSource={role_ids}
                renderItem={(item) => (
                    <List.Item>
                        <Flex style={{ width: "100%" }} justify='space-between' gap={10}>
                            {roles.find((r) => r.name === item)?.description}
                            {
                                    <Button type="primary" danger onClick={() => {
                                        showDeleteConfirmUserRole(item)
                                    }}>Delete</Button>
                            }

                        </Flex>

                    </List.Item>
                )}
            />
        }
    ];

    return <div
        style={{ padding: "10px" }}
    >
        {editUserModal && <EditUserModal setIsModalOpen={setEditUserModal} isModalOpen={editUserModal} editData={user} />}
        {assingRoleModal && <AssignRoleModal role_ids={role_ids} setRoleIds={setRoleIds} user_id={user.id} setIsModalOpen={setAssingRoleModal} isModalOpen={assingRoleModal} role_id={""} />}
        <Flex justify="space-between">
            <Typography.Title> User information</Typography.Title>
            <Flex gap={10}>
                {
                    <Button type="primary"
                        style={{ background: "rgb(101, 59, 250)" }} onClick={() => {
                            setEditUserModal(true)
                        }}>Edit</Button>
                }
                {
                    isLoadingDeleteUser ?
                        <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                        /> :
                        <Button type="primary" danger onClick={() => {
                            showDeleteConfirm(user)
                        }}>Delete</Button>
                }

            </Flex>
        </Flex>
        <Descriptions layout="vertical" bordered items={items} />
    </div>

}


export default UserInformation;