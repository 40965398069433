import { Button, Flex, List, Select, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { useGetRoles } from "../hooks/apis/rbac.api";
import Loader from "../components/Layout/loader";

const Permissions = () => {
    const { data: roles } = useSelector((state: RootState) => state.roles)
    const [roleId, setRoleId] = useState<string>(roles[0]?.name ? roles[0]?.name : "")
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const { isLoading } = useGetRoles()

    useEffect(() => {
        setRoleId(roles[0]?.name ? roles[0]?.name : "")
    }, [roles])

    if (isLoading) {
        return (
            <Flex style={{ height: "100vh" }}>
                {" "}
                <Loader />
            </Flex>
        );
    }



    return <div style={{ padding: "20px" }}>
        <Typography.Title>{`Permissions`}</Typography.Title>{" "}
        <Select
            style={{
                minWidth: "200px",
            }}
            showSearch
            placeholder="Select Role"
            optionFilterProp="children"
            filterOption={filterOption}
            value={roleId}
            onChange={setRoleId}
            options={roles.map(role => {
                return {
                    label: role.description,
                    value: role.name
                }
            })}
        />
        {roles.length > 0 && <div>
            <Tabs
                defaultActiveKey="1"
                items={ roleId && roleId !== "" && Object.entries(roles.find(role => role.name === roleId)?.permissions) && Object.entries(roles.find(role => role.name === roleId)?.permissions).length > 0 ? Object.entries(roles.find(role => role.name === roleId)?.permissions).map(([key, value]) => ({ key, value })).map((role: any) => {
                    return {
                        label: role.key,
                        key: role.key,
                        children:<List
                        style={{
                            height:"450px",
                            overflowY:"auto"
                        }}
                        bordered
                        dataSource={role.value}
                        renderItem={(item: string) => (
                          <List.Item>
                            <Typography.Text></Typography.Text> {item}
                          </List.Item>
                        )}
                      />
                    }
                }):[]}

            />
        </div>
        }

    </div>;
};
export default Permissions