import { api } from "@data-phone/react-resource-server-auth"
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux"
import { setRoles } from "../../redux/slices/roles.slice";
import { useMessageToast } from "../../components/Layout/DefaultLayout";

export interface Role {
    name: string;
    description: string;
    permissions: any
}

const getRolesApi = async () => {
    const response = await api.get(`${process.env.REACT_APP_AUTH_API}/rbac/roles`)
    return response
}

const getRoleByIdApi = async (id: string) => {
    const response = await api.get(`${process.env.REACT_APP_AUTH_API}/rbac/user-roles/${id}`)
    return response.data
}

const assignRoleApi = async (data: {
    user_id: string;
    role_id: string;
}) => {
    const response = await api.post(`${process.env.REACT_APP_AUTH_API}/rbac/user-roles/${data.user_id}`, {
        roles: [data.role_id]
    })
    return response
}

const deleteUserRole = async ({
    user_id,
    role_id
}:{
    user_id: string;
    role_id: string;
}) => {
    await api.delete(`${process.env.REACT_APP_AUTH_API}/rbac/user-roles/${user_id}?role=${role_id}`)
    return role_id
}


export const useGetRoles = () => {
    const dispatch = useDispatch()
    return useQuery("roles", getRolesApi, {
        enabled: false,
        onSuccess: (response) => {
            dispatch(setRoles(response.data))
        }
    })
}




export const useGetRoleById = (id: string) => {
    return useQuery("role", () => getRoleByIdApi(id), {
        enabled: false,
    })
}


export const useAssignRole = () => {
    const { messageApi } = useMessageToast()
    return useMutation(assignRoleApi, {
        onSuccess: (response) => {
            messageApi.success("Role assigned successfully")
        },
        onError: (error: any) => {
            if (error.response.data.errors[0].msg) {
                messageApi.error(error.response.data.errors[0].msg)
            } else {
                messageApi.error("Something went wrong")
            }
        }
    })
}


export const useDeleteUserRole = (setRoleIds:React.Dispatch<React.SetStateAction<string[]>>) => {
    const { messageApi } = useMessageToast()
    return useMutation(deleteUserRole, {
        onSuccess: (response) => {
            messageApi.success("Role deleted successfully")
            setRoleIds(prev => prev.filter(role => role !== response))
        },
        onError: (error: any) => {
            if (error.response.data.errors[0].msg) {
                messageApi.error(error.response.data.errors[0].msg)
            } else {
                messageApi.error("Something went wrong")
            }
        }
    })
}

