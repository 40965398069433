import { Button, Flex, Form, FormProps, Input, Select, Switch } from "antd"
import { useMessageToast } from "../Layout/DefaultLayout"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAddUser, User, useUpdtateUser } from "../../hooks/apis/users.api"
import { UserOutlined } from "@ant-design/icons";
import { MdOutlineMailOutline } from "react-icons/md";

import { RootState } from "../../redux"



const UserForm = ({
    setIsModal,
    editData,

}: {
    setIsModal: React.Dispatch<React.SetStateAction<boolean>>
    editData: User | null
}) => {

    const [form] = Form.useForm();
    const { data } = useSelector((state: RootState) => state.accounts)
    const { mutate: addUserMutation, isSuccess: isSuccessAddUser, isLoading: isLoadingAddUser } = useAddUser()
    const { mutate: updateUserMutation, isSuccess: isSuccessUpdateUser, isLoading: isLoadingUpdateUser } = useUpdtateUser()
    const { messageApi } = useMessageToast();

    useEffect(() => {
        form.resetFields()
    }, [])

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData)
        } else {
            form.resetFields()
        }
    }, [editData])

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const onFinish: FormProps<User>["onFinish"] = (values) => {
        if (!editData) {
            addUserMutation(values)
            return
        }
        const updateData:{
            id:string
            updateUserData:User
        } = {
            id: editData.id,
            updateUserData:values
        }
        updateData.id = editData.id
        if (form.getFieldValue("password") == "" || !form.getFieldValue("password")) {
            delete updateData.updateUserData.password
        }
        updateUserMutation(updateData)
    }

    useEffect(() => {
        if (isSuccessAddUser) {
            messageApi.success("User added successfully")
            setIsModal(false)
        }
    }, [isSuccessAddUser])

    useEffect(() => {
        if (isSuccessUpdateUser) {
            messageApi.success("User updated successfully")
            setIsModal(false)
        }
    }, [isSuccessUpdateUser])

    return <Form
        style={{ padding: "10px" }}
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        onFinish={onFinish}
    >
        <Form.Item<User>
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: "Please input your first name" }]}
        >
            <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="First Name"
            />
        </Form.Item>
        <Form.Item<User>
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: "Please input your last name" }]}
        >
            <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Last Name"
            />
        </Form.Item>

        <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!", type: "email" }]}
            label="Email"
        >
            <Input
                prefix={<MdOutlineMailOutline className="site-form-item-icon" />}
                placeholder="Email"
            />
        </Form.Item>
        <Form.Item<User>
            label="Account"
            name="account_id"
            rules={[{ required: true, message: "Please Select Account" }]}
        >
            <Select
                showSearch
                placeholder="Select Account"
                optionFilterProp="children"
                filterOption={filterOption}
                options={data.map(account => {
                    return {
                        label: account.name,
                        value: account.id
                    }
                })}
            />
        </Form.Item>

        <Form.Item
            name="password"
            label="Password"
            rules={[{ required: editData ? false : true, message: "Password must be at least 8 characters long, contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 symbol", pattern: editData ? undefined : /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/ }]}
        >
            <Input.Password
                placeholder="Password"
            />
        </Form.Item>

        <Form.Item>
            <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={isLoadingAddUser || isLoadingUpdateUser}
                loading={isLoadingAddUser || isLoadingUpdateUser}
                htmlType="submit"
            >
                {editData ? "Update User" : "Add User"}
            </Button>
        </Form.Item>
    </Form>

}

export default UserForm