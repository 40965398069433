import { Button, Flex, Form, FormProps, Input, Select, Switch } from "antd"
import { useMessageToast } from "../Layout/DefaultLayout"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../redux"
import { Account, useAddAccount, useUpdateAccount } from "../../hooks/apis/accounts.api"
import TextArea from "antd/es/input/TextArea"



const AccountForm = ({
    setIsModal,
    editData,
}: {
    setIsModal: React.Dispatch<React.SetStateAction<boolean>>
    editData: Account | null
}) => {

    const [form] = Form.useForm();
    const { mutate: addAccountMutation, isSuccess: isSuccessAddAccount, isLoading: isLoadingAddAccount } = useAddAccount()
    const { mutate: updateAccountMutation, isSuccess: isSuccessUpdateAccount, isLoading: isLoadingUpdateAccount } = useUpdateAccount()

    useEffect(() => {
        form.resetFields()
    }, [])


    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData)
        } else {
            form.resetFields()
        }
    }, [editData])


    const onFinish: FormProps<Account>["onFinish"] = (values) => {
        if (!editData) {
            addAccountMutation(values)
            return
        }
        const updateData: {
            id: string
            updateAccount: Account
        } = {
            id: editData.id,
            updateAccount: values
        }
        updateData.id = editData.id

        updateAccountMutation(updateData)
    }

    useEffect(() => {
        if (isSuccessAddAccount) {
            setIsModal(false)
        }
    }, [isSuccessAddAccount])

    useEffect(() => {
        if (isSuccessUpdateAccount) {
            setIsModal(false)
        }
    }, [isSuccessUpdateAccount])

    return <Form
        style={{ padding: "10px" }}
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        onFinish={onFinish}
    >
        <Form.Item<Account>
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input account name" }]}
        >
            <Input
                placeholder="Account Name"
            />
        </Form.Item>
        <Form.Item<Account>
            name="accountcode"
            label="Code"
            rules={[{ required: true, message: "Please input account code " }]}
        >
            <Input
                placeholder="Account Code"
            />
        </Form.Item>

        <Form.Item<Account>
            name="domain"
            rules={[{ required: true, message: "Please input account domain" }]}
            label="Domain"
        >
            <Input
                placeholder="Domain"
            />
        </Form.Item>
        <Form.Item<Account>
            label="Description"
            name="description"
            rules={[{ required: false }]}
        >
            <TextArea
                placeholder="Description"
            />
        </Form.Item>

        <Form.Item>
            <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={isLoadingAddAccount || isLoadingUpdateAccount}
                loading={isLoadingAddAccount || isLoadingUpdateAccount}
                htmlType="submit"
            >
                {editData ? "Update Account" : "Add Account"}
            </Button>
        </Form.Item>
    </Form>

}

export default AccountForm