var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect, useRef } from 'react';
import Cookies from "js-cookie";
import { jwtDecode } from 'jwt-decode';
import useLocalStorage from '../useStorageHook';
import { loginUser, logoutUser, refresh, sessionData } from '../../Apis';
export var useAuthModule = function () {
    var storage = useLocalStorage("local");
    var _a = useState({ isAuthenticated: false, nextRefreshTime: null, sessionData: null }), data = _a[0], setData = _a[1];
    var timeoutIdRef = useRef(null);
    useEffect(function () {
        storage.init("nextRefreshAuth", Cookies.get("sid") ? jwtDecode(Cookies.get("sid")) && jwtDecode(Cookies.get("sid")).exp ? jwtDecode(Cookies.get("sid")).exp : null : null);
        storage.init("isRefreshing", false);
    }, []);
    useEffect(function () {
        if (data.isAuthenticated) {
            getSession();
        }
    }, [data.isAuthenticated]);
    useEffect(function () {
        if (!storage)
            return;
        var setCallback = function (key) {
            if (key === "nextRefreshAuth") {
                var nextRefreshAuth = storage.get("nextRefreshAuth");
                if (!nextRefreshAuth)
                    return;
                var timeoutInMilliseconds = nextRefreshAuth * 1000 - Date.now() - ((Math.floor(Math.random() * 6) + 1) * 10000);
                if (timeoutInMilliseconds <= 0)
                    return;
                if (timeoutIdRef.current)
                    clearTimeout(timeoutIdRef.current);
                timeoutIdRef.current = setTimeout(function () {
                    if (storage.get("isRefreshing") == false) {
                        refreshToken(); // Call refreshToken
                    }
                }, timeoutInMilliseconds);
            }
            else if (key === "isRefreshing") {
                if (storage.get("isRefreshing") === true) {
                    var isRefreshingTimeout_1 = setTimeout(function () {
                        storage.set("isRefreshing", false);
                        clearTimeout(isRefreshingTimeout_1);
                    }, 60000);
                }
            }
        };
        storage.on("set", setCallback);
        return function () {
            if (timeoutIdRef.current)
                clearTimeout(timeoutIdRef.current);
            storage.off("set", setCallback);
        };
    }, [storage]); //
    var onLoad = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, payload_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    token = Cookies.get('sid');
                    if (!!token) return [3 /*break*/, 1];
                    clearSession();
                    return [2 /*return*/];
                case 1:
                    payload_1 = jwtDecode(token);
                    if (!(payload_1.exp && payload_1.exp < Date.now() / 1000)) return [3 /*break*/, 3];
                    return [4 /*yield*/, refreshToken()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    if (payload_1.exp && payload_1.exp > Date.now() / 1000) {
                        setData(function (prev) { return (__assign(__assign({}, prev), { isAuthenticated: true, nextRefreshTime: payload_1.exp ? payload_1.exp * 1000 : null })); });
                        storage.set("nextRefreshAuth", payload_1.exp);
                    }
                    else {
                        setData({ sessionData: null, isAuthenticated: false, nextRefreshTime: null });
                        storage.set("nextRefreshAuth", null);
                        clearSession();
                    }
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    clearSession();
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        onLoad();
    }, []);
    var refreshToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, response, token_1, payload_2, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    token = Cookies.get('sid');
                    if (!token) {
                        clearSession();
                        return [2 /*return*/];
                    }
                    storage.set("isRefreshing", true);
                    return [4 /*yield*/, refresh()];
                case 1:
                    response = _a.sent();
                    if (response.code == "200") {
                        token_1 = Cookies.get('sid');
                        payload_2 = jwtDecode(token_1);
                        if (payload_2.exp) {
                            setData(function (prev) { return (__assign(__assign({}, prev), { isAuthenticated: true, nextRefreshTime: payload_2.exp ? payload_2.exp * 1000 : null })); });
                            storage.set("nextRefreshAuth", payload_2.exp);
                        }
                    }
                    return [2 /*return*/, response];
                case 2:
                    error_2 = _a.sent();
                    clearSession();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getSession = function () { return __awaiter(void 0, void 0, void 0, function () {
        var session_1, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, sessionData()];
                case 1:
                    session_1 = _a.sent();
                    if (session_1.data) {
                        setData(function (prev) { return (__assign(__assign({}, prev), { sessionData: session_1.data })); });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    clearSession();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var clearSession = function () {
        Cookies.remove('sid', { domain: 'dataphone.cloud' });
        Cookies.remove('__Google-access-token', { domain: 'dataphone.cloud' });
        Cookies.remove('__Google-refresh-token', { domain: 'dataphone.cloud' });
        setData(function (prev) { return ({ isAuthenticated: false, nextRefreshTime: null, sessionData: null }); });
        window.localStorage.clear();
        if (window.location.origin !== "".concat(process.env.REACT_APP_LOGIN_PORTAL)) {
            window.location.href = "".concat(process.env.REACT_APP_LOGIN_PORTAL, "/login?redirectUrl=").concat(window.location.href);
        }
    };
    var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, logoutUser()];
                case 1:
                    response = _a.sent();
                    clearSession();
                    return [2 /*return*/, response];
                case 2:
                    error_4 = _a.sent();
                    clearSession();
                    console.log("error", error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var login = function (credentials) { return __awaiter(void 0, void 0, void 0, function () {
        var response, token, payload_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, loginUser(credentials)];
                case 1:
                    response = _a.sent();
                    if (response.code == 200) {
                        token = Cookies.get('sid');
                        payload_3 = jwtDecode(token);
                        if (payload_3.exp) {
                            setData(function (prev) { return (__assign(__assign({}, prev), { isAuthenticated: true, nextRefreshTime: payload_3.exp ? payload_3.exp * 1000 : null })); });
                            storage.set("nextRefreshAuth", payload_3.exp);
                        }
                    }
                    return [2 /*return*/, response];
            }
        });
    }); };
    return { data: data, login: login, logout: logout };
};
export default useAuthModule;
